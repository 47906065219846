<template>
    <Guest>
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-white uppercase">
                <i18n-t keypath="login.title1" />
            </h2>
            <h2 class="mt-2 text-center text-3xl leading-9 font-extrabold text-white uppercase">
                <i18n-t keypath="login.title2" />
            </h2>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form v-on:submit.prevent="login">
                    <TextInput v-model:value="form.email.value" v-model:errors="form.email.errors" :label="$t('login.email.title')" :placeholder="$t('login.email.placeholder')" autofocus border="enable" />
                    <TextInput v-model:value="form.password.value" v-model:errors="form.password.errors" :label="$t('login.password.title')" :placeholder="$t('login.password.placeholder')" password class="mt-4" border="enable" />

                    <div class="mt-6 flex items-center justify-between">
                        <div class="flex items-center">
                            <input id="remember_me" type="checkbox" v-model="form.remember_me" class="transition duration-150 ease-in-out">
                            <label for="remember_me" class="ml-2 block text-xs sm:text-sm leading-5 text-gray-800">
                                <i18n-t keypath="login.remember" />
                            </label>
                        </div>

                        <div class="text-sm leading-5">
                            <router-link :to="{ name: 'password.forgot'}" class="link text-xs sm:text-sm">
                                <i18n-t keypath="login.forgot.button" />
                            </router-link>
                        </div>
                    </div>

                    <div class="mt-6 grid grid-cols-1 gap-3">        
                        <button type="submit" class="button w-full">
                            <i18n-t keypath="login.submit" />
                        </button>
                        <router-link :to="{ name: 'register'}" class="button-black shadow-sm border-gray-400 w-full text-center">
                            <i18n-t keypath="register.button" />
                        </router-link>
                    </div>
                </form>
            </div> 
        </div>
    </Guest>
</template>

<script>
import { login } from "@/helper/api/AuthenticationHelper";
import Guest from "@/wrappers/Guest";
import TextInput from "@/components/forms/TextInput";


export default {
    name: "Login",
    components:{
        Guest,
        TextInput
    },
    data() {
        return {
            form: {
                email: {
                    value: '',
                    errors: []
                },
                password: {
                    value: '',
                    errors: []
                },
                remember_me: false,
            },
            error_message: "",
        }
    },
    methods: {
        login() {
            login(this.form, this.form.remember_me)
                .then(()=>{
                    const nextRoute = this.$route.query.next ?? "/dashboard";
                    this.$router.push(nextRoute);
                })
                .catch((data)=>{
                    this.error_message = data.message;

                    for (let error in data.errors) {
                        this.form[error].errors = data.errors[error];
                    }
                });
        }
    }
}
</script>